// IMPORT BASICS
import React from "react";

// IMPORT STYLES
import styles from "./Button.module.scss";

// ****************************************
// BUTTON (Component)
// Draws the button
// ****************************************

const Button = (props) => {
  // PROPS
  const { values, children, execute, language } = props;
  values["lan"] = language;

  // STATES

  //FUNCTIONS

  return (
    <div onClick={execute} className={styles["container"]}>
      {children}
    </div>
  );
};

export default Button;
