// IMPORT BASICS
import React, { useState } from "react";

// IMPORT STYLES
import styles from "./Info.css";

// IMPORT COMPONENT
import InfoIcon from "@mui/icons-material/Info";
import Popover from "@mui/material/Popover";

// ****************************************
// INFO (Component)
// Draws the Info Button
// ****************************************
const Info = (props) => {
  // PROPS
  const { info } = props;

  // STATE
  const [anchorEl, setAnchorEl] = React.useState(null);

  // FUNCTIONS
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="info_container">
      <InfoIcon className="info_icon" aria-describedby={id} onClick={(e) => handleClick(e)} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="info_popover">
          <p>{info}</p>
        </div>
      </Popover>
    </div>
  );
};

export default Info;
