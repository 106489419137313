import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import logo from "./logo.svg";
import "./App.css";

// IMPORT SITES
import Home from "./pages/Home";

var hist = createBrowserHistory();

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#5c0149",
    },
    secondary: {
      main: "#e3581c",
    },
  },
  typography: {
    h1: {
      fontSize: 90,
      fontWeight: 500,
      lineHeight: 1.2,
      fontFamily: "Varela Round,Helvetica,Arial,sans-serif",
    },
    h2: {
      fontSize: 32,
      fontWeight: 500,
    },
    fontSize: 17,
    fontFamily: "Varela Round,Helvetica,Arial,sans-serif",
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router history={hist}>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
