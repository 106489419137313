export const settings = {
  arbeitswochen_pj: 52.07,
  lohnkennzahlen: {
    mindestjahreslohn: 21510,
    koordinationsabzug: 25095,
    bvg_maximallohn: 86049,
    max_koord_lohn: 60945,
    min_koord_lohn: 3585,
    max_vers_uvg_lohn: 148200,
    min_vers_ktg_lohn: 315000,
  },
  bezahlte_feiertage: 0.032,
  dreizehnter: 0.0833,
  sonstige_absenzen: 0.0,
  ferienrueckstellung: {
    gav: {
      "0_19": 0.106,
      "20_24": 0.0833,
      "25_30": 0.0833,
      "31_34": 0.0833,
      "35_44": 0.0833,
      "45_49": 0.0833,
      "50_54": 0.106,
      "55_65": 0.106,
      "65_69_mit_BVG": 0.106,
      "65_plus_ohne_BVG": 0.106,
    },
    nongav: {
      "0_19": 0.0833,
      "20_24": 0.0833,
      "25_30": 0.0833,
      "31_34": 0.0833,
      "35_44": 0.0833,
      "45_49": 0.0833,
      "50_54": 0.0833,
      "55_65": 0.0833,
      "65_69_mit_BVG": 0.0833,
      "65_plus_ohne_BVG": 0.0833,
    },
  },
  ferien: {
    "4_wochen": 0.0833,
    "5_wochen": 0.106,
    "6_wochen": 0.1302,
    "7_wochen": 0.1553,
    "8_wochen": 0.1815,
  },
  gav_threshold: 62.55,
  altersgutschrift: {
    grade1: {
      arbeitgeber: {
        "0_19": 0.0,
        "20_24": 0.0,
        "25_30": 0.035,
        "31_34": 0.035,
        "35_44": 0.05,
        "45_49": 0.075,
        "50_54": 0.075,
        "55_65": 0.09,
        "65_69_mit_BVG": 0.09,
        "65_plus_ohne_BVG": 0.0,
      },
      arbeitnehmer: {
        "0_19": 0.0,
        "20_24": 0.0,
        "25_30": 0.035,
        "31_34": 0.035,
        "35_44": 0.05,
        "45_49": 0.075,
        "50_54": 0.075,
        "55_65": 0.09,
        "65_69_mit_BVG": 0.09,
        "65_plus_ohne_BVG": 0.0,
      },
    },
    grade4: {
      arbeitgeber: {
        "0_19": 0.0,
        "20_24": 0.0,
        "25_30": 0.03,
        "31_34": 0.1,
        "35_44": 0.1,
        "45_49": 0.1,
        "50_54": 0.1,
        "55_65": 0.1,
        "65_69_mit_BVG": 0.1,
        "65_plus_ohne_BVG": 0.0,
      },
      arbeitnehmer: {
        "0_19": 0.0,
        "20_24": 0.0,
        "25_30": 0.03,
        "31_34": 0.1,
        "35_44": 0.1,
        "45_49": 0.1,
        "50_54": 0.1,
        "55_65": 0.1,
        "65_69_mit_BVG": 0.1,
        "65_plus_ohne_BVG": 0.0,
      },
    },
    grade9: {
      arbeitgeber: {
        "0_19": 0.0,
        "20_24": 0.0,
        "25_30": 0.06,
        "31_34": 0.2,
        "35_44": 0.2,
        "45_49": 0.2,
        "50_54": 0.2,
        "55_65": 0.2,
        "65_69_mit_BVG": 0.2,
        "65_plus_ohne_BVG": 0.0,
      },
      arbeitnehmer: {
        "0_19": 0.0,
        "20_24": 0.0,
        "25_30": 0.0,
        "31_34": 0.0,
        "35_44": 0.0,
        "45_49": 0.0,
        "50_54": 0.0,
        "55_65": 0.0,
        "65_69_mit_BVG": 0.0,
        "65_plus_ohne_BVG": 0.0,
      },
    },
  },
  ktg: {
    grade1: {
      arbeitgeber: {
        mann: 0.0163,
        frau: 0.02322,
      },
    },
    grade4: {
      arbeitgeber: {
        mann: 0.0163,
        frau: 0.02322,
      },
    },
    grade9: {
      arbeitgeber: {
        mann: 0.01781,
        frau: 0.02634,
      },
    },
  },
  versicherungspraemie: {
    grade1: {
      arbeitgeber: {
        bvg_obligatorium: 0.0065,
        bvg_ueberobligatorium: 0.0,
        uvg_berufsunfall: 0.000467,
        uvg_zusatzversicherung: 0.0025,
        uvg_ueberschusslohn: 0.00399,
        uvg_nichtberufsunfall: 0.0,
      },
      arbeitnehmer: {
        bvg_obligatorium: 0.0065,
        bvg_ueberobligatorium: 0.0,
        uvg_berufsunfall: 0.0,
        uvg_zusatzversicherung: 0.0,
        uvg_ueberschusslohn: 0.0,
        uvg_nichtberufsunfall: 0.0068,
      },
    },
    grade4: {
      arbeitgeber: {
        bvg_obligatorium: 0.0095,
        bvg_ueberobligatorium: 0.0,
        uvg_berufsunfall: 0.000467,
        uvg_zusatzversicherung: 0.0025,
        uvg_ueberschusslohn: 0.00399,
        uvg_nichtberufsunfall: 0.0,
      },
      arbeitnehmer: {
        bvg_obligatorium: 0.0095,
        bvg_ueberobligatorium: 0.0,
        uvg_berufsunfall: 0.0,
        uvg_zusatzversicherung: 0.0,
        uvg_ueberschusslohn: 0.0,
        uvg_nichtberufsunfall: 0.0068,
      },
    },
    grade9: {
      arbeitgeber: {
        bvg_obligatorium: 0.019,
        bvg_ueberobligatorium: 0.0,
        uvg_berufsunfall: 0.000467,
        uvg_zusatzversicherung: 0.00554,
        uvg_ueberschusslohn: 0.00456,
        uvg_nichtberufsunfall: 0.0,
      },
      arbeitnehmer: {
        bvg_obligatorium: 0.0,
        bvg_ueberobligatorium: 0.0,
        uvg_berufsunfall: 0.0,
        uvg_zusatzversicherung: 0.0,
        uvg_ueberschusslohn: 0.0,
        uvg_nichtberufsunfall: 0.0068,
      },
    },
  },
  sozialversicherung: {
    arbeitgeber: {
      ahv: 0.053,
      ahv_verwaltungskosten: 0.00303,
      fak: 0.0112,
      alv1: 0.011,
      alv2: 0.005,
    },
    arbeitnehmer: {
      ahv: 0.053,
      ahv_verwaltungskosten: 0.0,
      fak: 0.0,
      alv1: 0.011,
      alv2: 0.005,
    },
  },
  weiteres: {
    arbeitgeber: {
      kant_berufsbildung: 0.001,
      berufsbeitrag: 0.003,
    },
    arbeitnehmer: {
      kant_berufsbildung: 0.001,
      berufsbeitrag: 0.007,
    },
  },
  msp: {
    grade1: 0.045,
    grade4: 0.045,
    grade9: 0.045,
  },

  payrolling_fee_pauschal: 0,
  payrolling_fee_all_in: 0,
};
