export const labels = {
  DE: {
    sex: "Geschlecht*",
    age: "Geburtsdatum (TT.MM.JJJJ)*",
    age_addon: "Datum",
    age_placeholder: "TT.MM.JJJJ",
    target_for: "Zielwert für*",
    target_for_info: "Gemäss Pensum",
    target: "Zielwert",
    sollstunden: "Sollstunden pro Arbeitswoche*",
    sollstunden_info: "Bei 100% Pensum",
    pensum: "Pensum*",
    stundenprotag: "Stunden pro Tag*",
    ferien: "Ferien pro Jahr*",
    ferien_info:
      "Bis zum zurückgelegten 20. Altersjahr haben Sie Anspruch auf 25 Arbeitstage (5 Wochen) Urlaub pro Jahr. Ist Ihr monatlicher Basislohn unter 12'350 CHF und haben Sie zusätzlich Ihr 50. Altersjahr vollendet, haben Sie ebenfalls Anspruch auf 25 Arbeitstage (5 Wochen) Urlaub pro Jahr.",
    sonstige_absenzen: "Sonstige Absenzen",
    msp: "MSP Fee",
    arbeitgeber: "Arbeitgeber",
    arbeitnehmer: "Arbeitnehmer",
    payrolling: "Payrolling Fee",
    pauschale: "Pauschale",
    gav: "Bitte Mindestlohn gemäss GAV Personalverleih beachten.",
    kosten: "= Kosten",
    quell: "Quellensteuer ?",
    kanton: "Kanton*",
    children: "Anzahl Kinder*",
    confession: "Konfession*",
    tarif: "Tarifgruppe*",
    hover:
      "Quellensteuerpflichtig sind alle ausländischen Arbeitnehmenden, die in der Schweiz wohnen oder Grenzgänger sind. Von der Quellensteuer befreit sind ausländische Personen mit einer Niederlassungsbewilligung C oder deren Ehepartner*in die Niederlassungsbewilligung C oder das Schweizer Bürgerrecht besitzt.",
    tarif_info:
      "* Effektive Tarifberechnungen werden von der zuständigen Stelle durchgeführt.",
    select_a_value: "Bitte geben Sie etwas ein",
    hoverFerien_intern:
      "Bis zum zurückgelegten 20. Altersjahr beträgt der Urlaubsanspruch 25 Arbeitstage pro Jahr. Ist der monatliche Basislohn unter 12'350 CHF und hat der Mitarbeitende das 50. Altersjahr vollendet, beträgt der Urlaubsanspruch ebenfalls 25 Arbeitstage pro Jahr. ",
    children_error: "Kinder eingeben",
    canton_error: "Kanton eingeben",
    confession_error: "Konfession eingeben",
    tarif_error: "Tarif eingeben",
    hours_pw_error: "Stunden pro Arbeitswoche eingeben",
    pensum_error: "Pensum eingeben",
    stunden_pd_error: "Stunden pro Tag eingeben",
    target_error: "Zielwert eingeben",
    tarif_code_error: "Dieser Tarif nicht in diesem Kanton",
    birthday_format: "Alter im Format tt.mm.jjjj eingeben",
    ferien_korrektur: "Bitte um Korrektur (siehe info button)",
    buffer: "Zusätzliche Rückstellungen ?",
    popup_sparbetrag: "Berechnung inklusive BVG-Sparbeitrag durchführen?",
    payrolling_fee: "Payrolling Fee Typ",
    chf: "in CHF",
    prozent: "in %",
    payroll_addon_chf: "CHF",
    payroll_addon_pro: "%",
    grade1: "Grade 1",
    grade4: "Grade 4",
    grade9: "Grade 9",
    prov_add_on: "Bez.",
    provision_1: "Zusätzliche Rückstellung 1",
    provision_2: "Zusätzliche Rückstellung 2",
    provision_3: "Zusätzliche Rückstellung 3",
    provision_4: "Zusätzliche Rückstellung 4",
    provision_5: "Zusätzliche Rückstellung 5",
    ja: "Ja",
    nein: "Nein",
    //Kontaktdaten
    kontaktdaten: "Ihre Kondaktdaten",
    versenden: "Anfragen",
    abbrechen: "Abbrechen",
    name: "Name*",
    vorname: "Vorname*",
    adresse: "Adresse und Hausnummer*",
    postleitzahl: "Postleitzahl*",
    stadt: "Stadt*",
    land: "Land*",
    telefon: "Telefonnummer*",
    email: "E-mail*",
    form_error: "Achtung Pflichtfeld",
    contact_info: "Ich habe Interesse",
    vertrags_entwurf: "Ich habe Interesse",
    vertrags_entwurf_info:
      "Haben Sie Fragen zu Ihrer Kalkulation? Gerne können Sie das untenstehende Formular ausfüllen. Wir werden uns baldmöglichst bei Ihnen melden",
    quell_titel: "Quellensteuer",
    kontaktland: "Schweiz",
    empty: "Achtung Pflichtfeld",
    format: "Formatfehler",
    gdpr_error: "Bitte Datenschutzrichtlinien akzeptieren",
    children_household: "Im selben Haushalt lebend?*",
  },
  EN: {
    sex: "Sex*",
    age: "Date of birth (DD.MM.YYYY)*",
    age_addon: "Date",
    age_placeholder: "DD.MM.YYYY",
    target_for: "Target value*",
    target_for_info: "According to workload",
    target: "Target",
    sollstunden: "Target hours per week*",
    sollstunden_info: "At 100% workload",
    pensum: "Workload*",
    stundenprotag: "Hours per day*",
    ferien: "Vacation days per year*",
    ferien_info:
      "You are entitled to 25 working days (5 weeks) of holiday per year up to the age of 20. If your basic monthly salary is less than CHF 12,350 and you have also reached your 50th birthday, you are also entitled to 25 working days (5 weeks) of vacation per year.",
    sonstige_absenzen: "Other absences",
    msp: "MSP fee",
    arbeitgeber: "Employer",
    arbeitnehmer: "Employee",
    payrolling: "Payrolling fee",
    pauschale: "Flat rate",
    gav: "Please note the minimum wage according to GAV guidelines.",
    kosten: "= Cost",
    quell: "Tax at source ?",
    kanton: "Canton*",
    children: "Number of children*",
    confession: " Confession*",
    tarif: "Tarif group",
    hover:
      "All foreign employees living in Switzerland who do not have a residence permit (permit C) and who earn less than CHF 120,000 a year are subject to withholding tax, as are people living abroad who earn an income in Switzerland (e.g. cross-border commuters , weekly residents, speakers, athletes, artists).",
    tarif_info:
      "* Effective calculations of tarifs will be executed by correspondant authorities.",
    select_a_value: "Select a value",
    hoverFerien_intern:
      "Up to the age of 20, the holiday entitlement is 25 working days per year. If the monthly base salary is less than CHF 12,350 and the employee has reached the age of 50, the holiday entitlement is also 25 working days per year.",
    children_error: "Type in a number of children",
    canton_error: "Type in a canton",
    confession_error: "Type in a confession",
    tarif_error: "Type in a tarif",
    hours_pw_error: "Type in hours per week",
    pensum_error: "Type in Workload in %",
    stunden_pd_error: "Type in hours per day",
    target_error: "Type in target",
    tarif_code_error: "This tarif not in this canton",
    birthday_format: "Input age in Format dd.mm.yyyy",
    ferien_korrektur: "Please correct input (see info button)",
    buffer: "Additional provisions ?",
    popup_sparbetrag:
      "Carry out calculation including BVG savings contribution?",
    payrolling_fee: "Payrolling fee type",
    chf: "in CHF",
    prozent: "in %",
    payroll_addon_chf: "CHF",
    payroll_addon_pro: "%",
    grade1: "Grade 1",
    grade4: "Grade 4",
    grade9: "Grade 9",
    prov_add_on: "Des.",
    provision_1: "Additional provision 1",
    provision_2: "Additional provision 2",
    provision_3: "Additional provision 3",
    provision_4: "Additional provision 4",
    provision_5: "Additional provision 5",
    ja: "Yes",
    nein: "No",
    //Contact Information
    kontaktdaten: "Your contact information",
    versenden: "Send",
    abbrechen: "Cancel",
    name: "Surname*",
    vorname: "Name*",
    adresse: "Address and Nr.*",
    postleitzahl: "Postal code*",
    stadt: "City*",
    land: "Country*",
    telefon: "Phone number*",
    email: "Email*",
    form_error: "Invalid Field",
    contact_info: "I'm interested",
    vertrags_entwurf: "I'm interested",
    vertrags_entwurf_info:
      "Do you have questions about your calculation? You are welcome to fill out the form below. We will contact you as soon as possible",
    quell_titel: "Tax At Source",
    // error message english
    kontaktland: "Switzerland",
    empty: "Entry Required",
    format: "Invalid Format",
    gdpr_error: "Please Accept Data Protection Policy",
    children_household: "living in the same household?*",
  },
};

export const buttons = {
  DE: {
    mehr: "+ Mehr Anzeigen",
    weniger: "- Weniger Anzeigen",
    calculate: "Berechnen",
  },
  EN: {
    mehr: "+ Show More",
    weniger: "- Show Less",
    calculate: "Calculate",
  },
};

export const pdf = {
  DE: {
    kundenrate: "Kundenrate",
    gebuehren: "- Gebühren",
    payrolling_fee: "Payroll Fee",
    msp_fee: "MSP Fee",
    allin: "= All-In Lohnkosten",
    ag_total: "- Beiträge Arbeitgeber",
    bgv_or: "BVG Risiko-Prämie & Admin-Gebühr",
    bgv_os: "BVG Sparbeitrag",
    bgv_ueo: "BVG Überobligatorium",
    ahv: "AHV / IV / EO",
    ahv_verwaltung: "AHV Verwaltungskosten",
    fak: "Familienausgleichskasse FAK",
    alv1: "ALV I Beitrag bis CHF 148'200 pro Jahr",
    alv2: "ALV II Beitrag ab CHF 148'200 pro Jahr",
    uvg_bu: "UVG - Berufsunfall (bis CHF 148'200 pro Jahr)",
    uvg_zv: "UVG - Zusatzversicherung (bis CHF 148'200 pro Jahr)",
    uvg_uel: "UVG - Überschusslohn (ab CHF 148'200 bis 315'000 pro Jahr)",
    uvg_nbu: "UVG - Nichtberufsunfall bis CHF 148'200 pro Jahr",
    ktg_regulaer: "KTG bis max. CHF 315'000 pro Jahr",
    kant_bbb: "Kantonaler Berufsbildungsbeitrag",
    bbb: "Berufsbeitrag GAV Personalverleih bis CHF 148'200 pro Jahr",
    bruttolohn: "= Bruttolohn",
    basissatz: "Basislohn",
    rs_absenzen: "+ Aufschläge",
    bezahlte_feiertage: "Feiertagsentschädigung",
    ferienrueckstellung: "Ferienentschädigung",
    dreizehnter: "13. Monatslohn",
    sonstige_absenzen: "Sonstige Absenzen & Aufschläge",
    an_total: "- Beiträge Arbeitnehmer",
    netto: "= Nettolohn vor Steuern",
    quell_abzug: "- Quellsteuerabzug",
    netto_quell: "= Nettolohn nach Quellensteuer",
    verrechenbarestunden: "Verrechenbare Stunden",
    verrechenbaretage: "Verrechenbare Tage",
    pensum: "Pensum in % ",
    vl_risiko: "BVG versicherter Lohn Risiko & Admin",
    vl_spar: "BVG Versicherter Lohn Sparbeitrag",
    berechnung_1: "Berechnet zu einem Pensum von ",
    berechnung_2: "auf Basis von ",
    berechnung_3:
      " Vollzeit-Sollstunden inkl. Ferien und Feiertagen pro Woche. Gemäss GAV (42h inkl. Ferien und Feiertagen) entspricht dies einem Pensum von ",
    ohnegewaehr:
      "Diese Online-Berechnung ist ohne Gewähr – definitive Berechnung erfolgt bei Erstellung des Arbeitsvertrag.",
    berechnung_fuer: "Berechnung für ",
    urlaub:
      "Urlaub wird bei effektivem Bezug ausbezahlt, ein allfälliger Restanspruch wird zu Ende der Anstellung ausbezahlt.",
    pro_stunde: "Pro Stunde",
    pro_tag: "Pro Tag",
    pro_monat: "Pro Monat",
    pro_jahr: "Pro Jahr",
    mindestlohn: "Bitte Mindestlohn gemäss GAV Personalverleih beachten.",
    buffer: "- Zusätzliche Rückstellungen",
  },
  EN: {
    kundenrate: "Bill rate",
    gebuehren: "Fees",
    payrolling_fee: "Payroll fee",
    msp_fee: "MSP fee",
    allin: "= All-In rate",
    ag_total: "Contributions employer",
    bgv_or: "BVG risk premium & administrative cost",
    bgv_os: "BVG savings bonus",
    bgv_ueo: "BVG supplementary",
    ahv: "AHV / IV / EO",
    ahv_verwaltung: "AHV administrative levies",
    fak: "FAK family compensation fund",
    alv1: "ALV I contribution below CHF 148'200 per year",
    alv2: "ALV II contribution above CHF 148'200 per year",
    uvg_bu: "UVG occupational accident (below CHF 148'200 per year)",
    uvg_zv: "UVG supplemental insurance (below CHF 148'200 per year)",
    uvg_uel: "UVG excess wages (between CHF 148'200 and 315'000 per year)",
    uvg_nbu: "UVG - non-occupational accident below CHF 148'200 per year",
    ktg_regulaer: "KTG up to a maximum of CHF 315'000 per year",
    kant_bbb: "Cantonal vocational training contribution",
    bbb: "Professional contribution GAV employee leasing up to CHF 148'200 per year",
    bruttolohn: "= Gross salary",
    basissatz: "Base salary",
    rs_absenzen: "+ Surcharges",
    bezahlte_feiertage: "Public holiday compensation",
    ferienrueckstellung: "Vacation compensation",
    dreizehnter: "13. Paycheck",
    sonstige_absenzen: "Other absences and surcharges",
    an_total: "- Contributions employee",
    netto: "= Net salary before tax",
    quell_abzug: "- Tax at source",
    netto_quell: "= Net salary after tax at source",
    verrechenbarestunden: "Billable hours",
    pensum: "Workload in % ",
    verrechenbaretage: "Billable days",
    vl_risiko: "BVG insured salary risk & admin",
    vl_spar: "BVG insured salary savings",
    berechnung_1: "Calculated at a workload of ",
    berechnung_2: "on the basis of ",
    berechnung_3:
      " full-time target hours including holidays and public holidays per week. According to the GAV (42 hours including holidays and public holidays), this corresponds to a workload of ",
    ohnegewaehr:
      "This online calculation is without guarantee - the definitive calculation will be presented upon contract creation",
    berechnung_fuer: "Calculation for ",
    urlaub:
      "Vacation is disbursed when actually taken, any remaining balance is disbursed at the end of the employment.",
    pro_stunde: "Per hour",
    pro_tag: "Per day",
    pro_monat: "Per month",
    pro_jahr: "Per year",
    mindestlohn: "Please note the minimum wage according to GAV guidelines.",
    buffer: "- Additional provisions",
  },
};
