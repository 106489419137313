// IMPORT BASICS
import React, { useRef } from "react";

//IMPORT COMPONENTS
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

const TableToPrintHeader = (props) => {
  const { label, result } = props;
  return (
    <TableRow>
      <TableCell>
        <h2 style={{ margin: "0px" }}>{label}</h2>
      </TableCell>
      <TableCell>
        <p className="home_headerNumber">Grade 1</p>
        <p className="home_subheaderNumber">Basic</p>
      </TableCell>
      {result.grade4.bruttolohn * result.grade4.config.multiplier > 86400 ? (
        <TableCell>
          <p className="home_headerNumber">Grade 4</p>
          <p className="home_subheaderNumber">Pension</p>
        </TableCell>
      ) : null}
      {result.grade9.isgav === "nongav" && result.grade9.bruttolohn * result.grade9.config.multiplier > 86400 ? (
        <TableCell>
          <p className="home_headerNumber">Grade 9</p>
          <p className="home_subheaderNumber">Max</p>
        </TableCell>
      ) : null}
    </TableRow>
  );
};

export default TableToPrintHeader;
