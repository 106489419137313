import { StylesContext } from "@mui/styles";
import React, { useState } from "react";

// IMPORT COMPONENTS
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AddCircleIcon from "@mui/icons-material/AddCircle";

// IMPORT CUSTOM
import Info from "../Info/Info.js";

// IMPORT UTILS
import { format } from "../../utils/format.js";

// IMPORT STYLE
import "./Line.css";

const Line = (props) => {
  // PROPS
  const { content, result, language } = props;
  const { label_DE, grade1, grade4, grade9 } = content;

  // STATE
  const [open, setOpen] = useState(false);

  // CALCULATION
  const containerClass = open ? "line_subContainerOpen" : "line_subContainerClosed";
  const iconClass = open ? "line_iconOpen" : "line_iconClosed";
  let lineClass = grade1.design === "total" ? "line_total" : null;
  lineClass = grade1.design === "fee" ? "line_fee" : null;

  return (
    <>
      <TableRow className={"line_container " + lineClass} key={"main"}>
        <TableCell>
          <div className="line_label">
            <p>{content["label_" + language]}</p>
            {grade1.details ? (
              <div className={"line_icon " + iconClass}>
                <AddCircleIcon onClick={() => setOpen(!open)} />
              </div>
            ) : null}
          </div>
        </TableCell>
        <TableCell>
          <p className="line_number">{format(grade1.value)}</p>
        </TableCell>
        {result.grade4.bruttolohn * result.grade4.config.multiplier > 86400 ? (
          <TableCell>
            <p className="line_number">{format(grade4.value)}</p>
          </TableCell>
        ) : null}
        {result.grade9.isgav === "nongav" && result.grade9.bruttolohn * result.grade9.config.multiplier > 86400 ? (
          <TableCell>
            <p className="line_number">{format(grade9.value)}</p>
          </TableCell>
        ) : null}
      </TableRow>
      {grade1.details &&
        grade1.details.map((item, index) => {
          const valueGrade1 = format(grade1.details[index].value);
          const valueGrade4 = format(grade4.details[index].value);
          const valueGrade9 = format(grade9.details[index].value);
          const label = grade1.details[index]["label_" + language];
          const info = grade1.details[index]["info_" + language];
          if (valueGrade1 === "0.00" && valueGrade4 === "0.00" && valueGrade9 === "0.00") return null; // prettier-ignore
          return (
            <TableRow className={"line_subContainer " + containerClass} key={index}>
              <TableCell>
                <div className="line_subContainerLabel">
                  <p>{label}</p>
                  {info ? (
                    <div className="line_info">
                      <Info info={info} />
                    </div>
                  ) : null}
                </div>
              </TableCell>
              <TableCell>
                <p className="line_number">{valueGrade1}</p>
              </TableCell>
              {result.grade4.bruttolohn * result.grade4.config.multiplier > 86400 ? (
                <TableCell>
                  <p className="line_number">{valueGrade4}</p>
                </TableCell>
              ) : null}
              {result.grade9.isgav === "nongav" &&
              result.grade9.bruttolohn * result.grade9.config.multiplier > 86400 ? (
                <TableCell>
                  <p className="line_number">{valueGrade9}</p>
                </TableCell>
              ) : null}
            </TableRow>
          );
        })}
    </>
  );
};

export default Line;
