export const option_sex = {
  DE: [
    { label: "Mann", value: "mann" },
    { label: "Frau", value: "frau" },
  ],
  EN: [
    { label: "Male", value: "mann" },
    { label: "Female", value: "frau" },
  ],
};

export const option_age = {
  DE: [
    { label: "0 - 19", value: "0_19" },
    { label: "20 - 24", value: "20_24" },
    { label: "25 - 30", value: "25_30" },
    { label: "31 - 34", value: "31_34" },
    { label: "35 - 44", value: "35_44" },
    { label: "45 - 49", value: "45_49" },
    { label: "50 - 54", value: "50_54" },
    { label: "55 - 64/65", value: "55_65" },
    { label: "65/66 - 69 (mit BVG Sparen)", value: "65_69_mit_BVG" },
    { label: ">65/66 (ohne BVG Sparen)", value: "65_plus_ohne_BVG" },
  ],
  EN: [
    { label: "0 - 19", value: "0_19" },
    { label: "20 - 24", value: "20_24" },
    { label: "25 - 30", value: "25_30" },
    { label: "31 - 34", value: "31_34" },
    { label: "35 - 44", value: "35_44" },
    { label: "45 - 49", value: "45_49" },
    { label: "50 - 54", value: "50_54" },
    { label: "55 - 64/65", value: "55_65" },
    { label: "65/66 - 69 (incl. BVG savings)", value: "65_69_mit_BVG" },
    { label: ">65/66 (BVG savings excl.)", value: "65_69_mit_BVG" },
  ],
};

export const option_vacation = {
  DE: [
    { label: "4 Wochen", value: "4_wochen" },
    { label: "5 Wochen", value: "5_wochen" },
    { label: "6 Wochen", value: "6_wochen" },
    { label: "7 Wochen", value: "7_wochen" },
    { label: "8 Wochen", value: "8_wochen" },
  ],
  EN: [
    { label: "4 Weeks", value: "4_wochen" },
    { label: "5 Weeks", value: "5_wochen" },
    { label: "6 Weeks", value: "6_wochen" },
    { label: "7 Weeks", value: "7_wochen" },
    { label: "8 Weeks", value: "8_wochen" },
  ],
};

export const option_target_pro = {
  DE: [
    { label: "pro Stunde", value: "pro_stunde" },
    { label: "pro Tag", value: "pro_tag" },
    { label: "pro Monat", value: "pro_monat" },
    { label: "pro Jahr", value: "pro_jahr" },
  ],
  EN: [
    { label: "per hour", value: "pro_stunde" },
    { label: "per day", value: "pro_tag" },
    { label: "per month", value: "pro_monat" },
    { label: "per year", value: "pro_jahr" },
  ],
};

export const option_payrolling_fee = {
  DE: [
    { label: "In CHF pro Stunde", value: "CHF_pro_stunde" },
    { label: "In CHF pro Tag", value: "CHF_pro_tag" },
    { label: "In CHF pro Monat", value: "CHF_pro_monat" },
    { label: "In CHF pro Jahr", value: "CHF_pro_jahr" },
    { label: "In % des Bruttolohns", value: "perc_bruttolohn" },
    { label: "In % der Kundenrate", value: "perc_kundenrate" },
  ],
  EN: [
    { label: "In CHF per hour", value: "CHF_pro_stunde" },
    { label: "In CHF per day", value: "CHF_pro_tag" },
    { label: "In CHF per month", value: "CHF_pro_monat" },
    { label: "In CHF per year", value: "CHF_pro_jahr" },
    { label: "In % of gross salary", value: "perc_bruttolohn" },
    { label: "In % of bill rate", value: "perc_kundenrate" },
  ],
};

export const option_target_for = {
  DE: [
    { label: "Kundenrate", value: "bill_rate" },
    { label: "Bruttolohn", value: "bruttolohn" },
    { label: "Nettolohn", value: "nettolohn" },
    { label: "Basislohn", value: "basissatz" },
    { label: "All-In Lohnkosten", value: "all_in_lohnkosten" },
  ],
  EN: [
    { label: "Bill rate", value: "bill_rate" },
    { label: "Gross salary", value: "bruttolohn" },
    { label: "Net salary", value: "nettolohn" },
    { label: "Base salary", value: "basissatz" },
    { label: "All-In rate", value: "all_in_lohnkosten" },
  ],
};

export const option_payrolling_type = {
  DE: [
    { label: "in %", value: "percentage" },
    { label: "in CHF/h", value: "hour" },
  ],
  EN: [
    { label: "in %", value: "percentage" },
    { label: "in CHF/h", value: "hour" },
  ],
};

export const defaultValues = {
  lan: "DE",
  sex: "mann",
  age: "0_19",
  bir: "01.01.2004",
  tgt: 100,
  tgp: "pro_stunde",
  tgf: "bill_rate",
  hrw: 42,
  pen: 100,
  prf: "CHF_pro_stunde",
  prf_grade1: 3.5,
  prf_grade4: 4.5,
  prf_grade9: 4.5,
  pra: "CHF",
  vac: "4_wochen",
  msp: 0,
  soa: 0,
  bvg: null,
  ckt: false,
  ckh: false,
  chh: false,
  can: null,
  tar: null,
  chi: null,
  con: null,
  qsr: 0,
  qsm: 0,
  pro: [],
  contact: {
    name: "",
    vorname: "",
    adresse: "",
    postleitzahl: "",
    land: "Schweiz",
    telefon: "0041 ",
    email: "",
  },
};

export const option_haushalt = {};

export const option_canton = {
  DE: [
    { value: "AG", label: "Aargau" },
    { value: "AR", label: "Appenzell Ausserrhoden" },
    { value: "AI", label: "Appenzell Innerrhoden" },
    { value: "BL", label: "Basel-Land" },
    { value: "BS", label: "Basel-Stadt" },
    { value: "BE", label: "Bern" },
    { value: "FR", label: "Freiburg" },
    { value: "GE", label: "Genève" },
    { value: "GL", label: "Glarus" },
    { value: "GR", label: "Graubünden" },
    { value: "JU", label: "Jura" },
    { value: "LU", label: "Luzern" },
    { value: "NE", label: "Neuchâtel" },
    { value: "NW", label: "Nidwalden" },
    { value: "OW", label: "Obwalden" },
    { value: "SG", label: "St. Gallen" },
    { value: "SH", label: "Schaffhausen" },
    { value: "SZ", label: "Schwyz" },
    { value: "SO", label: "Solothurn" },
    { value: "TG", label: "Thurgau" },
    { value: "TI", label: "Ticino" },
    { value: "UR", label: "Uri" },
    { value: "VD", label: "Vaud" },
    { value: "VS", label: "Wallis" },
    { value: "ZG", label: "Zug" },
    { value: "ZH", label: "Zürich" },
  ],

  EN: [
    { value: "AG", label: "Aargau" },
    { value: "AR", label: "Appenzell Ausserrhoden" },
    { value: "AI", label: "Appenzell Innerrhoden" },
    { value: "BL", label: "Basel-Land" },
    { value: "BS", label: "Basel-Stadt" },
    { value: "BE", label: "Bern" },
    { value: "FR", label: "Freiburg" },
    { value: "GE", label: "Genève" },
    { value: "GL", label: "Glarus" },
    { value: "GR", label: "Graubünden" },
    { value: "JU", label: "Jura" },
    { value: "LU", label: "Luzern" },
    { value: "NE", label: "Neuchâtel" },
    { value: "NW", label: "Nidwalden" },
    { value: "OW", label: "Obwalden" },
    { value: "SG", label: "St. Gallen" },
    { value: "SH", label: "Schaffhausen" },
    { value: "SZ", label: "Schwyz" },
    { value: "SO", label: "Solothurn" },
    { value: "TG", label: "Thurgau" },
    { value: "TI", label: "Ticino" },
    { value: "UR", label: "Uri" },
    { value: "VD", label: "Vaud" },
    { value: "VS", label: "Wallis" },
    { value: "ZG", label: "Zug" },
    { value: "ZH", label: "Zürich" },
  ],
};

export const option_tarif_external = {
  DE: [
    { label: "Alleinstehend", value: "A" },
    { label: "Verheiratete Alleinverdiener", value: "B" },
    { label: "Verheiratete Doppelverdiener", value: "C" },
  ],
  EN: [
    { label: "Unattached", value: "A" },
    { label: "Maried single income earner", value: "B" },
    { label: "Maried double income earner", value: "C" },
  ],
};

export const option_tarif_internal = {
  DE: [
    { label: "(A) Alleinstehend", value: "A" },
    { label: "(B) Verheiratete Alleinverdiener", value: "B" },
    { label: "(C) Verheiratete Doppelverdiener", value: "C" },
    { label: "(E) Vereinfachtes Abrechnungsverfahren", value: "E" }, // prettier-ignore
    {label: "(F) Grenzgänger aus Italien mit ausserhalb der Schweiz erwerbstätigen Ehegatten",value: "F"}, // prettier-ignore
    {label: "(G) Ersatzeinkünfte ohne Ausbezahlung über die Arbeitgeber",value: "G"}, // prettier-ignore
    {label:"(H) Alleinstehende die mit Kindern/Unterstützungsbedürftige im gleichen Haushalt wohnen und deren Unterhalt zur Hauptsache bestreiten",value: "H"}, // prettier-ignore
    {label: "(L) Tarifcode A erfüllende Grenzgänger aus Deutschland",value: "L"}, // prettier-ignore
    {label: "(M) Tarifcode B erfüllende Grenzgänger aus Deutschland",value: "M" }, // prettier-ignore
    {label: "(N) Tarifcode C erfüllende Grenzgänger aus Deutschland",value: "N"}, // prettier-ignore
    {label: "(P) Tarifcode H erfüllende Grenzgänger aus Deutschland",value: "P"}, // prettier-ignore
    {label: "(Q) Tarifcode G erfüllende Grenzgänger aus Deutschland",value: "Q" }, // prettier-ignore
    {label: "(HE) Tarifcode für Verwaltungsräte",value: "HE" }, // prettier-ignore
    {label: "(ME) Tarifcode für Mitarbeiterbeteiligungen",value: "ME" }, // prettier-ignore
    {label: "(NO) Tarifcode im Falle einer Korrektur für Personen die fälschlicherweise an der Quelle besteuert wurden oder es fälschlicherweise nicht wurden",value: "NO" }, // prettier-ignore
    {label: "Tessin: (R) Tarifcode A erfüllende Grenzgänger aus Italien",value: "R" }, // prettier-ignore
    {label: "Tessin: (S) Tarifcode B erfüllende Grenzgänger aus Italien",value: "S" }, // prettier-ignore
    {label: "Tessin: (T) Tarifcode C erfüllende Grenzgänger aus Italien",value: "T" }, // prettier-ignore
    {label: "Tessin: (U) Tarifcode D erfüllende Grenzgänger aus Italien",value: "U" }, // prettier-ignore
    {label: "(SF) Tarifcode für Grenzgänger aus Frankreich nach der Sondervereinbarung der Kantone BE,BS, BL, JU, NE, SO, VD, VS",value: "SF" }, // prettier-ignore
    {label: "(PEL) Bezugsprovision elektronisch",value: "PEL" }, // prettier-ignore
    {label: "(PPA) Bezugsprovision Papier",value: "PPA" }, // prettier-ignore
    {label: "(MED) Höchstbetrag für das satzbestimmende Ehegatteneinkommen für den Tarifcode C (Code Medianwert)",value: "MED" }, // prettier-ignore
  ],
  EN: [
    { label: "(A) Unattached", value: "A" },
    { label: "(B) Maried single income earner", value: "B" },
    { label: "(C) Maried double income earner", value: "C" },

    { label: "(E) Simple accounting procedure", value: "E" }, // prettier-ignore
    {label: "(F) Cross-boarder commuters from Italy with a spouse that is employed outside of Switzerland",value: "F"}, // prettier-ignore
    {label: "(G) Substitute earnings without payment to employer",value: "G"}, // prettier-ignore
    {label:"(H) Unattached with children or a person of need living in the same household and who are the sole support",value: "H"}, // prettier-ignore
    {label: "(L) Cross-boarder commuters from Germany who fulfill tarifcode A requirements",value: "L"}, // prettier-ignore
    {label: "(M) Cross-boarder commuters from Germany who fulfill tarifcode B requirements",value: "M" }, // prettier-ignore
    {label: "(N) Cross-boarder commuters from Germany who fulfill tarifcode C requirements",value: "N"}, // prettier-ignore
    {label: "(P) Cross-boarder commuters from Germany who fulfill tarifcode H requirements",value: "P"}, // prettier-ignore
    {label: "(Q) Cross-boarder commuters from Germany who fulfill tarifcode G requirements",value: "Q" }, // prettier-ignore
    {label: "(HE) Tarifcode for board of directors members",value: "HE" }, // prettier-ignore
    {label: "(ME) Tarifcode employee participation",value: "ME" }, // prettier-ignore
    {label: "(NO) Tarifcode corrections in the case of wrongly taxing the person at the source or wrongly not taxing the person at the source",value: "NO" }, // prettier-ignore
    {label: "Tessin: (R)  Cross-boarder commuters from Italy who fulfill tarifcode A requirements",value: "R" }, // prettier-ignore
    {label: "Tessin: (S) Cross-boarder commuters from Italy who fulfill tarifcode B requirements",value: "S" }, // prettier-ignore
    {label: "Tessin: (T) Cross-boarder commuters from Italy who fulfill tarifcode C requirements",value: "T" }, // prettier-ignore
    {label: "Tessin: (U)  from Italy who fulfill tarifcode D requirements",value: "U" }, // prettier-ignore
    {label: "(SF) Cross-boarder commuters from France according to the special arrangement of the cantons BE,BS, BL, JU, NE, SO, VD, VS",value: "SF" }, // prettier-ignore
    {label: "(PEL) Provision electronic",value: "PEL" }, // prettier-ignore
    {label: "(PPA) Provision paper",value: "PPA" }, // prettier-ignore
    {label: "(MED) Maximum value for rate-determining income of spouse applied to Tarifcode C (Code Median value)",value: "MED" }, // prettier-ignore
  ],
};

export const option_children = {
  DE: [
    { label: "0", value: "0" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
  ],
  EN: [
    { label: "0", value: "0" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
  ],
};

export const option_children_household = {
  DE: [
    { label: "Ja", value: true },
    { label: "Nein", value: false },
  ],
  EN: [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ],
};

export const option_confession = {
  DE: [
    { label: "andere", value: 0 },
    { label: "evangelisch", value: 1 },
    { label: "christ-katholisch", value: 2 },
    { label: "römisch-katholisch", value: 3 },
  ],
  EN: [
    { label: "other", value: 0 },
    { label: "evangelic", value: 1 },
    { label: "christian-catholic", value: 2 },
    { label: "roman-catholic", value: 3 },
  ],
};

export const required = {
  default: ["hrw", "stunden_pd", "tgt", "pen"],
  quell: ["can", "chi", "con", "tar"],
  tarCodeError: ["error"],
  age: ["birthday_format"],
  ferien_anspruch: ["ferien_anspruch"],
  form: [
    "name",
    "vorname",
    "adresse",
    "telefon",
    "postleitzahl",
    "land",
    "email",
    "stadt",
    "gdpr",
  ],
};
