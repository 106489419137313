import { defaultValues } from "../data/options.data.js";

export const setInitialValues = () => {
  const path = window.location.href;
  const query = path.split("?")[1];
  const queryValues = {};
  if (query) {
    const subqueries = query.split("&");
    subqueries.forEach((i) => {
      const item = i.split("=");
      if (item[0] && item[1]) {
        let content = item[1];
        if (content === "true") content = true;
        if (content === "false") content = false;
        queryValues[item[0]] = content;
      }
    });
  }

  const initialValues = {};
  for (let key in defaultValues) {
    if (key !== "contact") {
      initialValues[key] = queryValues[key] ? queryValues[key] : defaultValues[key]; // prettier-ignore
    }
  }
  return initialValues;
};
